import React from 'react';
import Rotate from "react-reveal/Rotate"
import Fade from "react-reveal/Fade"

import { StaticImage } from "gatsby-plugin-image"

import Layout from '../components/Layout';
import Header from '../components/Header';
import Scroll from '../components/Scroll';
import Footer from '../components/Footer';

import gplay from '../assets/images/google-play-badge.svg';
import amazonstore from '../assets/images/amazon-appstore-badge-english-black.svg';

const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead">
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            width: "100%",
            padding: "0 2.0rem 20px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Rotate>
            <div style={{ margin: "0 auto", width: "20%", minWidth: "128px" }}>
              <StaticImage
                src="../assets/images/fotoo-logo.png"
                layout="fullWidth"
                loading="eager"
                placeholder="none"
                alt="Logo"
              />
            </div>
          </Rotate>

          <Fade duration={600} delay={1000} bottom distance="20px" cascade ssrReveal>
            <div style={{ width: "100%" }}>
              <div style={{ margin: "80px auto 0", width: "40%", minWidth: "320px" }}>
                <StaticImage
                  src="../assets/images/fotoo.png"
                  loading="eager"
                  placeholder="none"
                  layout="fullWidth"
                  alt="Fotoo"
                  style={{ marginRight: "40px" }}
                />
              </div>
              <p
                style={{
                  color: "white",
                  fontSize: "24px",
                  textAlign: "center",
                  lineHeight: "1.6",
                  textShadow: "1px 1px 6px #4B8984",
                  margin: "30px 20px 0",
                }}
              >
                Turn tablet and TV into digital photo frame
                <br /><br />
                <Scroll type="id" element="download">
                  <a href="#download" className="btn btn-outline btn-xl">
                    Download
                  </a>
                </Scroll>
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </header>

    <section className="download bg-primary text-center" id="download">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <h2 className="section-heading">
              Download now to get started!
            </h2>
            <p className="text-muted">Available in Google Play and Amazon Appstore</p>
            <br />
            <div className="badges">
              <a
                className="badge-link"
                href="https://play.google.com/store/apps/details?id=com.bo.fotoo"
              >
                <img src={gplay} alt="" />
              </a>
              <a
                className="badge-link"
                href="http://www.amazon.com/gp/mas/dl/android?p=com.bo.fotoo.amazon"
              >
                <img src={amazonstore} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="features" id="features">
      <div className="container">
        <div className="section-heading text-center">
          <h2>Fotoo - Digital Photo Slideshow Player</h2>
          <p className="text-muted">
            Revive your memories and make every moment count!
          </p>
          <hr />
        </div>
        <StaticImage
          src="../assets/images/mockup_1.jpg"
          layout="fullWidth"
          loading="eager"
          placeholder="none"
          style={{ margin: "0px 80px" }}
          alt="Logo"
        />
        <div className="row">
          <div className="col-lg-12 my-auto">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-screen-smartphone text-primary"></i>
                    <h3>Your Photos Anywhere</h3>
                    <p className="text-muted">
                      Google Photos, Google Drive, Dropbox, Microsoft OneDrive, SMB and local Gallery
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-camera text-primary"></i>
                    <h3>Nicely Decorated</h3>
                    <p className="text-muted">
                      Date Time, Weather, Photo Details such as filename, folder name, taken time and GPS location
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-present text-primary"></i>
                    <h3>Variety of Transitions</h3>
                    <p className="text-muted">
                      Various display and transition effects for you to explore
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-lock-open text-primary"></i>
                    <h3>Customized for You</h3>
                    <p className="text-muted">
                      Fully customizable options such as background music, schedules, etc.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </Layout>
);

export default IndexPage;
